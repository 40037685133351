import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable, catchError, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
const rootUrl = `${environment.apiUrl}`;

@Injectable({
  providedIn: 'root' 
})
export class AuthService {
  private loggedInStatus: boolean = false;
  private refreshTokenTimeout?: NodeJS.Timeout;

  constructor(private http: HttpClient,private cookieService: CookieService) {
    // const expire = +localStorage.getItem('access_token_expiry');
    // const token = localStorage.getItem('x-access-token');
    const expire = +this.cookieService.get('access_token_expiry');
    const token = this.cookieService.get('x-access-token');
    const refreshToken = this.cookieService.get('token');
    // console.log(refreshToken, "expire");
    const now = Date.now();
    if (token && refreshToken && expire && now < expire && this.refreshTokenTimeout == null) {
      const timeout = expire - now;
      this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe() , timeout);
    }
  }

  public postApiCall(apiData: any, url: string) {
    const uri = `${rootUrl}${url}`;
    return this.http.post<any>(uri, apiData)
      .pipe(catchError(this.errroHandler))
  }
  public patch(apiData: any, url: string) {
    const uri = `${rootUrl}${url}`;
    return this.http.patch<any>(uri, apiData)
      .pipe(catchError(this.errroHandler))
  }

  public patchApiCall(apiData: any, url: string) {
    const uri = `${rootUrl}${url}`;
    return this.http.patch<any>(uri, apiData)
      .pipe(catchError(this.errroHandler))
  }

  public errroHandler(error: HttpErrorResponse) {
    return throwError(error);
  }

  // interceptor used for refresh token
  public setLoggedInStatus(value: boolean) {
    this.loggedInStatus = value;
    console.log(this.loggedInStatus, "loggedInStatus");
  }

  public isLoggedIn() {
    console.log(this.loggedInStatus, "isLoggedIn");
    return this.loggedInStatus;
  }

  public getJwtToken() {
    // return localStorage.getItem('x-access-token');
    return this.cookieService.get('x-access-token');
  }

  public refreshToken(): Observable<any> {
    let url = `${rootUrl}auth/refresh-token`;
    let apiData = {
      'refresh_token': this.getRefreshToken()
    };
    return this.http.post<any>(url, apiData)
      .pipe(
        tap((tokens: any) => {
          this.storeJwtToken(tokens);
        })
      )
  }

  public startRefreshTokenTimer(token: any) {
    const expires = token["access_token_expiry"] * 1000;
    // localStorage.setItem('access_token_expiry', String(Date.now() + expires));
    this.cookieService.set('access_token_expiry', String(Date.now() + expires));
    const timeout = expires - (60 * 5 * 1000);
    this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe() , timeout);
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }

  public logoutCall(apiData: any) {
    const url = `${rootUrl}logout`;
    this.stopRefreshTokenTimer();
    return this.http.post<any>(url, apiData)
      .pipe(catchError(this.errroHandler))
  }

  public logout() { 
    this.cookieService.deleteAll();
    this.deleteAllCookies();
    localStorage.removeItem("userConfigList");
    localStorage.removeItem("x-access-token");
    localStorage.removeItem("token");
    localStorage.removeItem("UserId");
    localStorage.removeItem("access_token_expiry");
    this.stopRefreshTokenTimer();
          
  }

  public storeJwtToken(token: any) {
    this.cookieService.set('x-access-token', token.access_token);
    this.cookieService.set('token', token.refresh_token);
    // localStorage.setItem('x-access-token', token.access_token);
    // localStorage.setItem('token', token.refresh_token);
    this.startRefreshTokenTimer(token);
  }

  public getRefreshToken() {
   return this.cookieService.get('token');
   // return localStorage.getItem('token');
  }

  public putApiCall(apiData: any, url: string) {
    const uri = `${rootUrl}${url}`;
    return this.http.put<any>(uri, apiData)
      .pipe(catchError(this.errroHandler))
  }

  deleteAllCookies(): void {
    const cookies = document.cookie.split(";");
  
    for (const cookie of cookies) {
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
    }
  }
  
}