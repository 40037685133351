export const USER_STATUS_LABEL = {
  active: 'Active',
  inactive: 'Inactive',
};

export const BUTTON_LABEL = {
  userAdd: 'Add',
  userUpdate: 'Update',
  userAdding: 'Adding...',
  userUpdating: 'Updating...',
  companyAdd: 'Add',
  companyUpdate: 'Update',
  clientAdd: 'Add',
  clientUpdate: 'Update',
  clientAdding: 'Adding...',
  clientUpdating: 'Updating...',
  sendOktaActivation: 'Send Activation Link',
  resendOktaActivation: 'Resend Activation Link',
  alertAdd: 'Add',
  alertAdding:'Adding...',
  setpassword:'reset password'
};
export const QUARTERS = {
  q1: 'Q1',
  q2: 'Q2',
  q3: 'Q3',
  q4: 'Q4',
};
export const FORM_TYPE_LABEL = {
  form8k: '8-K',
};
export const FILTER_PLACEHOLDER = {
  companyListProductFilter: 'Filter by Product',
  companyListClientFilter: 'Filter by Channel',
  userListClientFilter: 'Filter by Channel',
  userListRoleFilter: 'Filter by Role',
  userListProductFilter: 'Filter by Product',
  auditLogFilter: 'Filter by Action',
  representativeFilter: 'Filter by Account Representative',
  companyListFilter : 'Filter by Company',
};
export const DROP_DOWN_PLACEHOLDER = {
  userSelectCompany: 'Choose Company(s)',
  userAllCompanySelected: 'All companies selected',
}
export const FORM_INPUT_PLACEHOLDER = {
  documentTitle: 'Title',
  wordMLdocuemntTitle: 'CUSIP'
}