export const REGEX = {
  email: /^[A-Z0-9_-]+([\.+][A-Z0-9_-]+)*@[A-Z0-9-]+(\.[a-zA-Z]{2,8})+$/i,
  onlyNumber: /^\d+$/,
  alphaNumeric: /^[0-9a-zA-Z]+$/,
  cusipPattern: /^([0-9a-zA-Z]{9})+$/,
  findOpenTagsWithXmlns:
    /(<[a-z]+[\-a-z]*[1-6]?[^><]*)(xmlns="\S*")([^><]*>)/gi,
  isNumeric: /^[0-9\-\.]+$/,
  isNegativeNumberWithLeftBracket: /^\([0-9\,\.]+$/gi,
  jcrFolder: /^[a-zA-Z0-9-_]+$/,
  libraryReleaseName: /^[a-zA-Z0-9-_]+$/,
  docReleaseNamePattern: /^[a-zA-Z0-9-_]+$/,
  contactName:
    /[A-Za-z0-9!#-$%():;'@\*\+\-/=\?\^_`~{|},. \\\s&lt;&gt;&amp;&quot;\[\]]{1,30}/,
  contactPhoneNumber: /[a-zA-Z0-9\s#(),.\-]{1,20}/,
  dosFileNumber:
    /\d{1,3}-\d{1,8}-[a-zA-Z0-9]{1,4}|\d{1,3}-\d{1,8}|24R-\d{1,8}-[a-zA-Z0-9]{1,4}|24R-\d{1,8} /,
  offeringFileNumber:
    /\d{1,3}-\d{1,8}-[a-zA-Z0-9]{1,4}|\d{1,3}-\d{1,8}|24R-\d{1,8}-[a-zA-Z0-9]{1,4}|24R-\d{1,8} /,
  contactEmailAddress:
    /([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,7}|[0-9]{1,3})(\]?)/,
  coIssuerFileNumber:
    /NEW|\d{1,3}-\d{1,8}-[a-zA-Z0-9]{1,4}|\d{1,3}-\d{1,8}|24R-\d{1,8}-[a-zA-Z0-9]{1,4}|24R-\d{1,8} /,
  nonNegativeInteger: /^\d+$/,
  zipCode: /[0-9A-Za-z #\-]*/,
  phoneNumber: /[a-zA-Z0-9\-]{1,20} /,
  connectionName:
    /[A-Za-z0-9!#-$%():;'@\*\+\-/=\?\^_`~{|},. \\\s&lt;&gt;&amp;&quot;\[\]]{1,30}/,
  connectionZipCode: /[0-9A-Za-z #\-]*/,
  connectionPhoneNumber: /[a-zA-Z0-9\s#(),.\-]{1,20}/,
  commentsEmailAddress:
    /([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,7}|[0-9]{1,3})(\]?)/,
  notificationEmailAddress:
    /([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,7}|[0-9]{1,3})(\]?)/,
  fractionalDigitsSixteen: /[\+\-]?\d{0,13}\.\d\d |(^0?$)/,
  pricePerSecurity: /[\+\-]?\d{0,13}\.\d\d\d\d/,
  formPhoneNumber:/^[0-9()-\s]+$/
};
