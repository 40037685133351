export const ALERT_ERROR_MESSAGE = {
  emailExist: 'Email Already Registered',
  alertExists: 'You are Already Tracking This Cik',
  ditaMapResourceNotSelect: 'Dita Map Folder not Selected',
  noFactList: 'Error in Current Fact List',
  rollforwardNotImplemented: 'Rollforward not Implemented',
  ditaMapNotFound: 'Dita Map Not Found',
  noRelease: 'No Release Found',
  ditaDetailNotAdded: 'Unable to Add Dita Details',
  ditaDetailNotUpdate: 'Unable to Update Dita Details',
  documentIdError: 'Document id Invalid',
  iMergixNotClosed: 'Please Save and Close Imergix Tab',
  unableToDownload: 'Unable to Download. Please Try Again',
  iXBRLNotGenerated: 'IXBRL is not Generated!',
  ditaOtError:
    'Error(s) Encountered in Document. Click on Download Log for Details',
  noEditorFacility: 'You Have no Editor Facility',
  clientAdd: 'Unable to Add Client',
  clientUpdate: 'Unable to Update Client',
  ditaMapTreeError: 'Unable to Save as File May be Locked by Other User',
  html2DitaError: 'Unable to Convert to Dita Table',
  renderingDocument: 'Error in Rendering Document.',
  DownloadError: 'File not Available for Download',
  EDGAR_EYE: {
    INVALID_FORM: 'Please Fill the Required Fields',
  }
};

export const ALERT_SUCCESS_MESSAGE = {
  newVersion :'New Version Upload Successful',
  userCreated: 'User Created Successfully',
  userUpdated: 'User Updated Successfully',
  companyCreated: 'Company Created Successfully',
  companyUpdated: 'Company Updated Successfully',
  documentCreated: 'Document Created Successfully',
  documentUpdated: 'Document Updated Successfully',
  documentUploaded: 'Document Uploaded Successfully',
  documentRenderingCompleted: 'Document Rendering is Completed.',
  documentRelease: 'Document Released Successfully',
  clientAdded: 'Channel Added Successfully',
  clientUpdate: 'Channel Updated Successfully',
  sqlExecuted: 'SQL Executed Successfully',
  ditaMapTreeSaved: 'Document Index saved successfully',
  addEditInfoUpdated: 'Add Edit Info Updated successfully',
  alertCreated: 'Alert Created Successfully',
  EDGAR_EYE: {
    ALERT_CREATED: "Alert Created Successfully",
    ALERT_UPDATED: "Alert Updated Successfully",
  },
  NEW_FORM_ADDED: "New Form Added Successfully"
};
export const ALERT_INFO_MESSAGE = {
  documentRenderInProcess: 'Preparing Document ({DOC_NAME}) for Review',
  documentRenderCompleted: 'Document ({DOC_NAME}) is Ready for Review',
  ixbrlErrorlog:
    'Inline Tagging Missing for one or More Facts. Click on Download Error Log for Details.',
};
