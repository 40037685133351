export const DELETE_MODAL_CONFIRMATION = {
  company: {
    heading: 'Delete Company',
    title: 'Are you sure to delete this company?',
    cancelTitle: 'Cancel',
    confirmYesTitle: 'Delete',
  },
  document: {
    heading: 'Delete Document',
    title: 'Are you sure to delete this document?',
    cancelTitle: 'Cancel',
    confirmYesTitle: 'Delete',
  },
  sql: {
    heading: 'Run Query',
    title:
      'You are running delete query, Do you really want to run this query?',
    cancelTitle: 'Cancel',
    confirmYesTitle: 'Run',
  },
  documents: {
    heading: 'Delete Documents',
    title: 'Are you sure to delete selected documents?',
    cancelTitle: 'Cancel',
    confirmYesTitle: 'Delete',
  },
};

export const ACTION_MODAL_CONFIRMATION = {
  createUser: {
    heading: 'Create User',
    title:
      'Selected channel is not mapped with any okta account. Do you want to proceed?',
    cancelTitle: 'Cancel',
    confirmYesTitle: 'Create',
  },
  updateUser: {
    heading: 'Update User',
    title:
      'Selected channel is not mapped with any okta account. Do you want to proceed?',
    cancelTitle: 'Cancel',
    confirmYesTitle: 'Update',
  },
  changeContentpane: {
    heading: 'Save Document Builder',
    title: 'There are some changes in document builder. Do you want to proceed without these changes?',
    cancelTitle: 'No',
    confirmYesTitle: 'Yes',
  },
  closeDocument: {
    heading: 'Close Document',
    title: 'Are you sure want to close this document?',
    cancelTitle: 'No',
    confirmYesTitle: 'Yes',
  }
};
