// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: "/",
  oktaConfig: {
    clientId: "0oakm7nzsoPBSAiJv5d6",
    issuer: "https://dev-54742573.okta.com/oauth2/default",
    redirectUri: "http://localhost:4200/login/callback",
    scopes: ["openid", "profile", "email"],
    pkce: true,
  },
  oxygenDetails: {
    url: "https://editor.xml-edge.com/web-author-component-integration/app/oxygen.html?url=dummy",
    ip: "18.189.134.245",
  },
  frontendEditor: true,
  jspreadsheet_license:
    "OGYzMmMxNDExZTBmYWVmYTIxZTU3MDllZTg0ODA5NjNmODJmMDU5MWFjMTJhM2Q3ZGM0MzBjZmVkMzg5ZGNkMTA4NjUyMTM0NmYwZmQ2ZmY4NzIzZDljYjM5YzhhMGQ1YzY5MWNkODBlZWY1NDIzNjRmZDdlN2JkYzFhYzFmOTYsZXlKdVlXMWxJam9pZUdVaUxDSmtZWFJsSWpveE5qYzVOekF5TkRBd0xDSmtiMjFoYVc0aU9sc2lNeTR4TXprdU1qZ3VNVFV4SWl3aWVHMXNMV1ZrWjJVdVkyOXRJaXdpTXk0eE1pNHhPRE11TWpVd0lpd2liRzlqWVd4b2IzTjBJbDBzSW5Cc1lXNGlPaUl6SWl3aWMyTnZjR1VpT2xzaWRqY2lMQ0oyT0NKZGZRPT0=",
  adobeClientId: "",//"d62c2faa27df472daf095edc98ee7f44",
  filing: {
    type: "test",
  },
  ckediorLicenseKey: "UG9Ub2JPTWdxbnZtMEI4NjQrTi9vVENzYk1CQlFrOTlqRlY1dVI5UTRJNldkRmZoTGNLUmEvSzUtTWpBeU5UQXhNak09",
  cardknoxKey:'ifields_edgaragents106e3d1858294f66b5418df0ee'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
