export const DOCUMENT_STATUS = {
  submitted: 4,
  none: 0,
  deleted: 6,
};

export const BACKEND_STATUS = {
  delete: 6,
};
export const DOC_PROCESS_STATUS = {
  notApplicable: 1,
  initialized: 2,
  pending: 3,
  completed: 4,
  error: 5,
};

export const DOC_PROCESS = {
  rendering: 1,
  commentTransfer: 2,
  chunking: 3,
};
export const REPORT_STATUS = {
  context: 0,
  unit: 1,
  negation: 2,
  extended: 3,
  footnote: 4,
  validation: 5,
  calculation: 6,
  schemaDiff: 7,
  xbrlVersionCompare: 8,
  srcVersionCompare: 9,
  comment: 10,
  approvalStatus: 11,
  arelleReport: 12,
  customReport: 13,
  concept: 14,
  fact: 15,
  commentApproval: 16,
  approval: 17,
  presentation: 19,
  filingSummary: 20,
  taxonomyChange: 21,
  role: 22,
  manualMapped: 23,
  ditaComment: 24,
  fercValidation: 25,
};
export const OKTA_USER_STATUS = {
  active: 'ACTIVE',
  staged: 'STAGED',
  provisioned: 'PROVISIONED',
  deprovisioned: 'DEPROVISIONED',
  suspended: 'SUSPENDED',
};
