import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ROUTE_PATHS } from "./core/constants";
import { OktaAuthGuard, OktaCallbackComponent } from "@okta/okta-angular";
import { UserConfigResolver } from "./core/resolver/user-config.resolver";
import { AuthGuard } from "./core/guards/auth.guard";
import { UserManagementGuard } from "./core/guards/user-management.guard";
import { HistoryLayoutComponent } from "./modules/history/history-layout/history-layout.component";
import { NotFoundComponent } from "./modules/not-found/not-found.component";

const routes: Routes = [
  // {
  //   path: 'login/callback',
  //   component: OktaCallbackComponent,
  // },
  {
    path: ROUTE_PATHS.ROOT,
    redirectTo: ROUTE_PATHS.xe_login,
    pathMatch: "full",
  },
  {
    path: ROUTE_PATHS.dashboard,
    loadChildren: () =>
      import("./modules/dashboard/dashboard.module").then(
        (m) => m.DashboardModule
      ),
  },
  {
    path: "auth",
    loadChildren: () =>
      import("./modules/auth/auth.module").then((m) => m.AuthModule),
  },
  {
    path: ROUTE_PATHS.COMPANIES,
    loadChildren: () =>
      import("./modules/company/company.module").then((m) => m.CompanyModule),
    canActivate: [AuthGuard],
  },
  {
    path: ROUTE_PATHS.USERS,
    loadChildren: () =>
      import("./modules/user/user.module").then((m) => m.UserModule),
    canActivate: [UserManagementGuard],
  },
  {
    path: `${ROUTE_PATHS.COMPANY}/:companyId/${ROUTE_PATHS.DOCUMENTS}`,
    loadChildren: () =>
      import("./modules/document/document.module").then(
        (m) => m.DocumentModule
      ),
  },
  {
    path: `${ROUTE_PATHS.COMPANY}/:companyId/${ROUTE_PATHS.DOCUMENTS}/${ROUTE_PATHS.content}`,
    loadChildren: () =>
      import("./modules/content-pane/content-pane.module").then(
        (m) => m.ContentPaneModule
      ),
  },
  {
    path: `${ROUTE_PATHS.eVox}/:companyId/${ROUTE_PATHS.DOCUMENTS}/${ROUTE_PATHS.content}`,
    loadChildren: () =>
      import("./modules/content-pane/content-pane.module").then(
        (m) => m.ContentPaneModule
      ),
  },
  {
    path: `library-edit`,
    loadChildren: () =>
      import("./modules/content-pane/content-pane.module").then(
        (m) => m.ContentPaneModule
      ),
  },
  {
    path: `library-editor`,
    loadChildren: () =>
      import("./modules/document-editor/document-editor.module").then(
        (m) => m.DocumentEditorModule
      ),
  },
  {
    path: `${ROUTE_PATHS.documentEdit}`,
    loadChildren: () =>
      import("./modules/document-editor/document-editor.module").then(
        (m) => m.DocumentEditorModule
      ),
  },
  {
    path: ROUTE_PATHS.roleManagement,
    loadChildren: () =>
      import("./modules/role-management/role-management.module").then(
        (m) => m.RoleManagementModule
      ),
  },
  {
    path: ROUTE_PATHS.client,
    loadChildren: () =>
      import("./modules/client-management/client-management.module").then(
        (m) => m.ClientManagementModule
      ),
    canLoad: [AuthGuard],
  },
  {
    path: ROUTE_PATHS.taxonomy,
    loadChildren: () =>
      import("./modules/taxonomy-search/taxonomy-search.module").then(
        (m) => m.TaxonomySearchModule
      ),
  },
  {
    path: ROUTE_PATHS.xeCompare,
    loadChildren: () =>
      import("./modules/xe-compare/xe-compare.module").then(
        (m) => m.XeCompareModule
      ),
    canLoad: [AuthGuard],
  },
  {
    path: ROUTE_PATHS.history,
    loadChildren: () =>
      import("./modules/history/history.module").then((m) => m.HistoryModule),
  },
  {
    path: ROUTE_PATHS.system,
    loadChildren: () =>
      import("./modules/system/system.module").then((m) => m.SystemModule),
  },
  {
    path: ROUTE_PATHS.library,
    loadChildren: () =>
      import("./modules/library/library.module").then((m) => m.LibraryModule),
    canLoad: [AuthGuard],
  },
  {
    path: ROUTE_PATHS.edgareye,
    loadChildren: () =>
      import("./modules/edgareye/edgareye.module").then(
        (m) => m.EdgareyeModule
      ),
    canLoad: [AuthGuard],
  },
  {
    path: ROUTE_PATHS.easyview,
    loadChildren: () =>
      import("./modules/easy-view/easy-view.module").then(
        (m) => m.EasyViewModule
      ),
    canLoad: [AuthGuard],
  },
  {
    path: ROUTE_PATHS.validator,
    loadChildren: () =>
      import("./modules/validator/validator.module").then(
        (m) => m.ValidatorModule
      ),
    canLoad: [AuthGuard],
  },
  {
    path: `${ROUTE_PATHS.easyview}/:companyId/${ROUTE_PATHS.projects}/${ROUTE_PATHS.content}`,
    loadChildren: () =>
      import("./modules/content-pane/content-pane.module").then(
        (m) => m.ContentPaneModule
      ),
  },
  {
    path: `${ROUTE_PATHS.validator}/:companyId/${ROUTE_PATHS.projects}/${ROUTE_PATHS.content}`,
    loadChildren: () =>
      import("./modules/content-pane/content-pane.module").then(
        (m) => m.ContentPaneModule
      ),
  },
  {
    path: ROUTE_PATHS.marketforms,
    loadChildren: () =>
      import("./modules/marketforms/marketforms.module").then(
        (m) => m.MarketformsModule
      ),
    canLoad: [AuthGuard],
  },
  {
    path: ROUTE_PATHS.xe_login,
    loadChildren: () =>
      import("./modules/login/login.module").then((m) => m.LoginModule),
  },
  {
    path: ROUTE_PATHS.xe_reset_password,
    loadChildren: () =>
      import("./modules/reset-password/reset-password.module").then(
        (m) => m.ResetPasswordModule
      ),
  },
  {
    path: ROUTE_PATHS.securex_reset_password,
    loadChildren: () =>
      import("./modules/reset-password/reset-password.module").then(
        (m) => m.ResetPasswordModule
      ),
  },
  {
    path: ROUTE_PATHS.cpu_reset_password,
    loadChildren: () =>
      import("./modules/reset-password/reset-password.module").then(
        (m) => m.ResetPasswordModule
      ),
  },
  {
    path: ROUTE_PATHS.cpu_login,
    loadChildren: () =>
      import("./modules/cpu-login/cpu-login.module").then((m) => m.cpuModule),
  },
  {
    path: ROUTE_PATHS.securex_login,
    loadChildren: () =>
      import("./modules/securex-login/securex-login.module").then(
        (m) => m.securexModule
      ),
  },
  {
    path: ROUTE_PATHS.imergix,
    loadChildren: () =>
      import("./modules/imergix/imergix.module").then((m) => m.ImergixModule),
    canLoad: [AuthGuard],
  },
  {
    path: ROUTE_PATHS.uploadFiling,
    loadChildren: () =>
      import("./modules/upload-filing/upload.module").then(
        (m) => m.UploadModule
      ),
  },
  {
    path: ROUTE_PATHS.setUserPassword,
    loadChildren: () =>
      import("./modules/set-user-password/set-user-password.module").then(
        (m) => m.SetUserPasswordModule
      ),
  },
  {
    path: ROUTE_PATHS.ckeditor,
    loadChildren: () =>
      import("./modules/ckeditor/ckeditor.module").then(
        (m) => m.CkeditorModule
      ),
  },
  {
    path: `${ROUTE_PATHS.ckeditor}/${ROUTE_PATHS.EDIT}/:companyId/:docId/:endocId/`,
    loadChildren: () =>
      import("./modules/ckeditor/ckeditor.module").then(
        (m) => m.CkeditorModule
      ),
  },
  {
    path: "not-found",
    loadChildren: () =>
      import("./modules/not-found/not-found.module").then(
        (m) => m.NotFoundModule
      ),
  },
  {
    path: ROUTE_PATHS.xe_setupauthenticator,
    loadChildren: () =>
      import("./modules/setup-authenticator/setup-authenticator.module").then(
        (m) => m.SetupAuthenticatorModule
      ),
  },
  {
    path: ROUTE_PATHS.cpu_setupauthenticator,
    loadChildren: () =>
      import("./modules/setup-authenticator/setup-authenticator.module").then(
        (m) => m.SetupAuthenticatorModule
      ),
  },
  {
    path: ROUTE_PATHS.securex_setupauthenticator,
    loadChildren: () =>
      import("./modules/setup-authenticator/setup-authenticator.module").then(
        (m) => m.SetupAuthenticatorModule
      ),
  },
  {
    path: ROUTE_PATHS.eVox,
    loadChildren: () =>
      import("./modules/evox/evox.module").then((m) => m.EvoxModule),
    canLoad: [AuthGuard],
  },
  {
    path: ROUTE_PATHS.EA_TSR,
    loadChildren: () =>
      import("./modules/shareholder-report/shareholder.module").then(
        (m) => m.ShareholderModule
      ),
  },
  {
    path: `${ROUTE_PATHS.EA_TSR}/:companyId/${ROUTE_PATHS.DOCUMENTS}/${ROUTE_PATHS.content}`,
    loadChildren: () =>
      import("./modules/content-pane/content-pane.module").then(
        (m) => m.ContentPaneModule
      ),
  },
  {
    path: `${ROUTE_PATHS.EA_FOURTY}`,
    loadChildren: () =>
      import("./modules/filings/filings.module").then((m) => m.FilingsModule),
  },
  {
    path: ROUTE_PATHS.EA_Forms,
    loadChildren: () =>
      import("./modules/ea-form/ea-form.module").then((m) => m.EaFormModule),
  },
  {
    path: `${ROUTE_PATHS.EA_Forms}/:companyID/:cik/:formID/${ROUTE_PATHS.PREVIEW_DOCUMENT}/:companyID`,
    loadChildren: () =>
      import("./modules/preview/preview.module").then((m) => m.PreviewModule),
  },
  {
    path:ROUTE_PATHS.EA_TSR_BUILDER,
    loadChildren: () =>
      import("./modules/ea-tsr/ea-tsr.module").then((m) => m.EaTsrModule),
    canLoad: [AuthGuard],
  },
  {
    path:ROUTE_PATHS.BOIR,
    loadChildren: () =>
    import('./modules/boir/boir.module').then((m) => m.BoirModule)
  },
  {
    path: ROUTE_PATHS.common,
    loadChildren: () =>
      import('./modules/unrestricted-access/unrestricted-access.module').then((m) => m.UnrestrictedAccessModule),
  },
  { path: "**", redirectTo: ROUTE_PATHS.ROOT },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: "reload" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
