export const MODULES = {
  easyview: 1,
  edgareye: 2,
  filingscms: 3,
  imergix: 4,
  library: 5,
  ea16: 6,
  xecompare: 7,
  evox: 8,
  eaconnect: 9,
  validator: 10,
  eaforms: 11,
  ea40: 12,
  tsr: 13,
  boir: 14,
};
