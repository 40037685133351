import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { ConfigService } from '../services';
import { CookieService } from "ngx-cookie-service";

@Injectable({
  providedIn: 'root',
})
export class UserManagementGuard implements CanActivate {
  constructor(private readonly configService: ConfigService,private cookieService: CookieService) {}
  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean>{
    const userConfig = this.configService.currentUserConfig();
    if (userConfig) {
      if (userConfig.Feature_UserManagement) {
        return true;
      } else {
        return false;
      }
    } else {
      const config:any = this.cookieService.get('userConfigList');
      // const config:any = localStorage.getItem('userConfigList');
      // const config = await this.getUserConfigurationPromise();
      // let userConfigData:any = {
      //   UserEmail: "admin@xmledge.com",
      //   UserName: "super admin",
      //   UserRoles: [{ RoleName: "Super Admin" }],
      //   logo: "assets/main/images/xmlEdge.png",
      //   showDocID: true
      // }
      this.configService.setUserConfig(JSON.stringify(config));
      if (config.singleCompany && 'CompanyID' in config.singleCompany) {
        this.configService.isOnlyReviewer = true;
      }
      return config.Feature_UserManagement;
    }
  }
  private getUserConfigurationPromise() {
    return this.configService.getUserConfiguration().toPromise();
  }
}
