export const VALIDATION_MESSAGE = {
  emailRequired: "Please enter an email address",
  emailPattern: "Please enter a valid email address",
  userNameRequired: "Please enter user name",
  firstNameRequired: "Please enter first name",
  lastNameRequired: "Please enter last name",
  clientRequired: "Please select a channel",
  roleRequired: "Please select at least one role",
  productRequired: "Please select at least one product",
  companyNameRequired: "Please enter company name",
  tikerRequired: "Please enter ticker",
  cikRequired: "Please enter cik",
  cidRequired: "Please enter cid",
  cikPattern: "Please enter only digits",
  cikLength: "Plese enter 10 digits",
  sicPattern: "Please enter only digits",
  sicLength: "Please enter 4 digits",
  cikOrcidRequired: "Please enter CIK or CID",
  cidAlphanumeric: "Please enter only numbers and letters",
  docTitleRequired: "Enter document title",
  cusipRequired: "Enter CUSIP",
  formTypeRequired: "Please select Form Type",
  xbrlFileRequired: "Please select a XBRL file",
  docPeriodDateRequired: "Please select period end date",
  releaseVersionRequird: "Please enter release version",
  clientNameRequired: "Please enter client name",
  ditaFolderRequired: "Please select a folder for dita mapping",
  ditaUrlRequired: "Please select a dita Url",
  roleNameRequired: "Please enter role name",
  clientLogoImageExtension: "Only jpg and png file allowed",
  clientLogoImageFileSize: "You can only upload up to 2 MB",
  documentTypeRequired: "Please select a Document Type",
  cusipPattern: "CUSIP must be alphanumeric and 9 digit length",
  fileAllowedWordML: "Only document file is allowed",
  fileAllowedImportExcel: "Only .xlsx is allowed",
  fileAllowedJcrContent: "Only zip file allowed",
  jcrFolderNameRequired: "Please enter folder name",
  jcrValidPattern:
    "Folder name can contain only '_', '-' or alphanumeric characters",
  libraryReleaseNameRequired: "Please enter revision description",
  libraryReleaseNameValidPattern:
    "Revision description can contain only 50 characters",
  docReleaseNameValidPattern:
    "Revision description can contain only 50 or alphanumer characters",
  // libraryReleaseNameRequired: 'Please enter release name',
  // libraryReleaseNameValidPattern: 'Release name can contain only \'_\', \'-\' or alphanumer characters',
  // docReleaseNameValidPattern: 'Release name can contain only \'_\', \'-\' or alphanumer characters',
  fileAllowedXML: "Only document .xml is allowed",
  fileAllowedHTML: "Only document .zip or .html is allowed",
  htmlFileRequired: "Please choose a valid HTML File",
  pdfFileRequired: "Please choose a valid PDF File",
  ixbrlFileRequired: "Please choose a valid iXBRL File",
  wordFileRequired: "Please choose a valid word File",
  fileNameRequired: "Please enter the file name",
  projectNameRequired: "Please enter the project name",
  formDescriptionRequired: "Please select form Description",
  methodRequired: "Please select a method",
  cccRequired: "Please enter ccc",
  cccLength: "Please enter 8 characters",
};

export const FormDValidation = {
  issuerName: "Please enter name of issuer",
  nameOfSigner: "Please enter name of signer",
  signature: "Please enter the signature",
  title: "Please enter the title",
  date: "Please enter the date",
  incorporation: "Please enter Jurisdiction of Incorporation/Organization",
  entity: "Please select an Entity",
  YearOfIncorporation: "Please select year of Incorporation/Organization",
  withinLastFiveYearsSpecify: "Please enter the year",
  streetAddress1: "Please enter Street Address",
  lastname: "Please enter Last Name",
  firstname: "Please enter First Name",
  city: "Please enter City",
  state: "Please enter State",
  zip: "Please enter ZIP/Postal Code",
  recipient: "Please enter Recipient",
  phone: "Please enter phone no",
  previousName1: "Please enter the previous Name",
  totalOfferingAmountRequired: "Please enter the total offering amount",
  totalAmountSoldRequired: "Please enter the total amount sold",
  numberPattern: "Please enter only numbers",
  addressAlphaNumeric: "Please enter only numbers and letters",
};

export const EaTsrValidations = {
  pleaseChooseAValidImageFile: "Please choose a valid image file",
};
