import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from "@angular/router";
import { ROUTE_PATHS } from "src/app/core/constants";
export interface User {
  id: number;
  username: string;
  token: string;
}

@Injectable({
  providedIn: "root",
})
export class UnrestrictedAccessService {
  private currentUserSubject: BehaviorSubject<User | null>;
  public currentUser: Observable<User | null>;

  constructor(private http: HttpClient, public router: Router) {
    this.currentUserSubject = new BehaviorSubject<User | null>(JSON.parse(localStorage.getItem('currentUser') || '{}'));
    this.currentUser = this.currentUserSubject.asObservable();
    console.log(this.currentUser, 'this.currentUserSubject', this.currentUserSubject);
  }

  getPdfPreviewData(form_id: any) {
    const formData = {
      form_id: form_id
    }
    const url = `${environment.apiUrl}boirforms/preview/getFormInfoForReview`
    return this.http.post(url, formData, { responseType: 'text' });
  }

 
  login(username: string, password: string): Observable<User> {
    return this.http.post<any>(`${environment.apiUrl}/login`, { username, password })
      .pipe(map(user => { 
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
        return user;
      }));
  }

  logout(access: any, user: any): void {
    localStorage.removeItem('gus_accessToken');
    localStorage.removeItem('gus_refreshToken');
    localStorage.removeItem('gus_username');
    localStorage.removeItem('gus_email');
    localStorage.removeItem('gus_id');
    localStorage.clear();
    this.router.navigate([`/${ROUTE_PATHS.xe_login}`], { queryParams: { user: user, access: access } });
  }

  isAuthenticated(): boolean { 
    return !!this.currentUser;
  }

  updatePreviewedStatus(formData: any) { 
    const url = `${environment.apiUrl}boirforms/preview/updatePreviewedStatus`
    return this.http.post(url, formData);
  }

  getFilingStatus(formData: any) { 
    const url = `${environment.apiUrl}boirforms/preview/getFilingStatus`
    return this.http.post(url, formData);
  }

  public approveAndFile(data: any): Observable<any> {
    const url: string = `${environment.apiUrl}boirforms/preview/approveAndFile`;
    return this.http.post(url, data);
  }
}