import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ROUTE_PATHS } from '../core/constants';

@Injectable({
  providedIn: 'root'
})
export class VersionCheckService {
  private currentVersion: any;
  newVersion = '';
  client: any;
  constructor(private http: HttpClient, private authservice: AuthService,
    private cookieService: CookieService, private readonly router: Router
  ) {
    this.client = localStorage.getItem("baseUrl");
  }

  ngOnInit() {
  }

  public initVersionCheck(url: string) {
    this.checkVersion(url).catch(err => {
      console.error('Error during version check:', err);
    });
  }
  private async checkVersion(url: string): Promise<void> {
    try {
      const response: any = await this.http.get(url).toPromise();
      this.newVersion = response.version;
      let oldversion: string | number | null = localStorage.getItem("version");
      let newversion: string | number | null = this.newVersion;

      if (oldversion)
        oldversion = Number(oldversion.replace(/\D/g, ''));
      if (newversion)
        newversion = Number(newversion.replace(/\D/g, ''));

      if (!oldversion)
        localStorage.setItem('version', this.newVersion?.toString());
      else if (oldversion < newversion)
        this.promptUser();

    } catch (error) {
      console.error('Error fetching version:', error);
    }

  }

  promptUser() {
    const userConfirmed = confirm(`A new version (${this.newVersion}) of the application is available. Click "OK" to load new version.`);
    if (userConfirmed) {
      this.clearcookiesandNegaviteLogin();
      localStorage.setItem('version', this.newVersion);
    }
  }

  clearcookiesandNegaviteLogin() {
    this.logoutNegavite();
    this.deleteAllCookies();
    localStorage.clear();
    sessionStorage.clear();
  }

  deleteAllCookies() {
    const allCookies = this.cookieService.getAll();
    Object.keys(allCookies).forEach(cookieName => {
      this.cookieService.delete(cookieName, '/');
    });
  }
  logoutNegavite() {
    let UserEmail = JSON.parse(this.cookieService.get("userConfigList"));
    let apiData = { email: UserEmail.UserEmail };
    let apiUrl = "auth/logout";
    this.authservice.postApiCall(apiData, apiUrl).subscribe({
      next: (logout: any) => {
        if (logout["status"] == 1) {
          localStorage.clear();
          this.deleteAllCookies();
          let baseUrl = this.client
          if (baseUrl) {
            this.router.navigateByUrl(baseUrl);
          } else {
            this.router.navigateByUrl(ROUTE_PATHS.xe_login);
          }
        } else {
         console.log("Error :", logout);
        }
      },
      error: (error) => {
         console.log("Error :", error);
      },
    });
  }
};

