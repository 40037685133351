import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, NO_ERRORS_SCHEMA,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
// import {
//   OKTA_CONFIG,
//   OktaAuthModule,
//   OktaAuthGuard,
//   OktaAuthService,
// } from '@okta/okta-angular';
import { environment } from '../environments/environment';
import { ToastrModule } from 'ngx-toastr';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { HttpConfigInterceptor } from './interceptor/httpconfig.interceptor';
import { AuthGuard } from './core/guards/auth.guard';
import { CompanyListComponent } from './modules/boir/company-list/company-list.component';
import { AddFilingComponent } from './modules/boir/add-filing/add-filing.component';
import { FilingListComponent } from './modules/boir/filing-list/filing-list.component';
import { BoirFormComponent } from './modules/boir/boir-form/boir-form.component'; 
import { CommonService } from './services/common.service';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    AngularMultiSelectModule,
    ToastrModule.forRoot()
  ],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA,CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    CommonService,
    // OktaAuthGuard,
    // OktaAuthService,
    // { provide: OKTA_CONFIG, useValue: environment.oktaConfig },
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
    AuthGuard
  ],
})
export class AppModule { }
