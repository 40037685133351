import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WindowHandlerService {

  activeImergixWindow: Window;
  constructor() { }

  setImergixActiveWindow(iMergixWindow: Window) {
    this.activeImergixWindow = iMergixWindow;
  }
  closeActiveImergixWindow() {
    if (this.activeImergixWindow && !this.activeImergixWindow.closed) {
      this.activeImergixWindow.close();
    }
  }
  isIMergixWindowActive() {
    return this.activeImergixWindow && !this.activeImergixWindow.closed;
  }
}
