import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  public isLoading$ = new BehaviorSubject(false);
  constructor() { }

  public start(): void  {
    this.isLoading$.next(true);
  }

  public stop(): void {
    this.isLoading$.next(false);
  }
}
