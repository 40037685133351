import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { BehaviorSubject, Observable, of, throwError } from "rxjs";
import { catchError, filter, switchMap, take } from "rxjs/operators";
import { ROUTE_PATHS } from "../core/constants";
import { UnrestrictedAccessService } from "../modules/unrestricted-access/unrestricted-access.service";
import { AuthService } from "../shared/services/auth.service";
@Injectable({
  providedIn: "root",
})
export class HttpConfigInterceptor implements HttpInterceptor {
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    false
  );
  public role_flag: any;
  public LogOut_OB: boolean = false;
  constructor(
      private authService: AuthService,
      private router: Router, public route: ActivatedRoute,
      public unrestrictedAccessService: UnrestrictedAccessService,
      public cookieService:CookieService
    )
       { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // const accessToken: any = localStorage.getItem("x-access-token");
    const accessToken: any = this.cookieService.get('x-access-token'); 
    const userDetails: any = localStorage.getItem("userDetailsData");
    const lengthOfUrl = req.url.split("/");
    if (userDetails) {
      req = req.clone({
        headers: req.headers.set("Meta-Info-XmlEdge", userDetails),
      });
    }

    if (lengthOfUrl.length <= 1) {
    } else if (
      req.url.includes("/auth/login") ||
      req.url.includes("/auth/verifyOtp") ||
      req.url.includes("/auth/sendOtpPostValidate") ||
      req.url.includes("/auth/update-user-password") ||
      req.url.includes("/auth/sync-keycloak")
    ) {
      req = req.clone({
        headers: req.headers.set("Content-Type", "application/json"),
      });
      req = req.clone({
        headers: req.headers.set("Accept", "application/json"),
      });
      // req = req.clone({
      //   headers: req.headers.set("Meta-Info-XmlEdge", `userDetails`),
      // });
    } else if (req.url.includes("/dita/config/") || req.url.includes("/dita/")) {
      // req = req.clone({
      //   headers: req.headers.set("Content-Type", "application/json"),
      // });
      req = req.clone({
        headers: req.headers.set("Authorization", `Bearer ${accessToken}`),
      });
      // req = req.clone({
      //   headers: req.headers.set("Meta-Info-XmlEdge", `userDetails`),
      // });
    } else if (req.url.includes("/auth/refresh-token")) {
      req = req.clone({
        headers: req.headers.set("Content-Type", "application/json"),
      });
      // req = req.clone({
      //   headers: req.headers.set("Meta-Info-XmlEdge", `userDetails`),
      // });
    } else if (
      (req.url.includes("/api/document") ||
        req.url.includes("/api/word/validate-style/") ||
        req.url.includes("/api/filings/add-filing") || req.url.includes("/api/filings/update-filing") || 
        req.url.includes("/api/client") || req.url.includes("/api/editor") || req.url.includes("boirforms/updateNewForm") || req.url.includes('boirforms/uploadBOIRCompanyDocument') || 
        req.url.includes("/draftable") || req.url.includes("/sso/user")) || req.url.includes("eaconnect") || req.url.includes("boirforms/bulkImport") &&
      (req.method.toUpperCase() === "POST" || req.method.toUpperCase() === "PUT" || req.method.toUpperCase() === "PATCH")
    ) {
      //   req = req.clone({
      //     headers: req.headers.set("Content-Type", "multipart/form-data"),
      //   });
      //   req = req.clone({
      //     headers: req.headers.set("Accept", "*/*"),
      //   });
      req = req.clone({
        headers: req.headers.set("Authorization", `Bearer ${accessToken}`),
      });
      // req = req.clone({
      //   headers: req.headers.set("Meta-Info-XmlEdge", `userDetails`),
      // });
    } else if (req.url.includes("/ea-tsr")) {
      req = req.clone({
        headers: req.headers.set("Authorization", `Bearer ${accessToken}`),
      });
      req = req.clone({
        headers: req.headers.set("Accept", "*/*"),
      });
    }else if (req.url.includes("boirforms/preview")) {
      req = req.clone({
        headers: req.headers.set("Content-Type", "application/json"),
      });
      req = req.clone({
        headers: req.headers.set("Accept", "application/json"),
      });
      req = req.clone({
        headers: req.headers.set("authorization", `Bearer ${localStorage.getItem("gus_accessToken")}`)
      });
    } else {
      req = req.clone({
        headers: req.headers.set("Content-Type", "application/json"),
      });
      req = req.clone({
        headers: req.headers.set("Accept", "*/*"),
      });
      req = req.clone({
        headers: req.headers.set("Authorization", `Bearer ${accessToken}`),
      });
      // req = req.clone({
      //   headers: req.headers.set("Meta-Info-XmlEdge", `userDetails`),
      // });
    }
    return next.handle(req).pipe(
      catchError((requestError) => {
        if (req.url.includes("/auth/refresh-token")) {
          this.authService.logout();
          let baseUrl = localStorage.getItem("baseUrl");
          if (baseUrl) {
            this.router.navigateByUrl(baseUrl);
          } else {
            this.router.navigateByUrl(ROUTE_PATHS.xe_login);
          }
        } else if (requestError.status == 401) {
          if (req.url.includes("/session/login")) {
            return throwError(requestError);
          } if (req.url.includes("/boirforms/preview")) {
            console.log(this.route.snapshot.queryParams, "req------------------------------------", req);
            let access = this.route.snapshot.queryParams['access'];
            let user = this.route.snapshot.queryParams['user'];
            if (access && user) {
              this.unrestrictedAccessService.logout(access, user);
            }
          } else {
            return this.handle401Error(req, next);
          }
        } else {
          return throwError(requestError);
        }
      })
    );
  }

  /*handle401Error(req: HttpRequest<any>, next: HttpHandler) {
    if (this.refreshTokenInProgress) {
      return this.refreshTokenSubject.pipe(
        filter((result) => result !== null),
        take(1),
        switchMap((jwt: any) => {
          console.log("jwt token...",jwt)
          return next.handle(this.addAuthenticationToken(req, jwt.token));
        })
      );
    } else {
      this.refreshTokenInProgress = true;
      this.refreshTokenSubject.next(null);
      return this.authService.refreshToken().pipe(
        switchMap((retrieveNewToken: any) => {
          console.log("refresh token...",retrieveNewToken)
          this.refreshTokenInProgress = false;
          this.refreshTokenSubject.next(retrieveNewToken.token);
          return next.handle(
            this.addAuthenticationToken(req, retrieveNewToken.token)
          );
        }),
        catchError((err) => {
          this.refreshTokenInProgress = false;
          this.authService.logout();
          let baseUrl = localStorage.getItem("baseUrl");
          console.log("baseUrl...",baseUrl)
          if (baseUrl) {
            this.router.navigateByUrl(baseUrl);
          } else {
            this.router.navigateByUrl(ROUTE_PATHS.xe_login);
          }
          return of(err);
        })
      );
    }
  }*/

    handle401Error(req: HttpRequest<any>, next: HttpHandler) {
      if (this.refreshTokenInProgress) {
        return this.refreshTokenSubject.pipe(
          filter((result) => result !== null),
          take(1),
          switchMap((jwt: any) => {
            // console.log("jwt token...", jwt);
            if (!jwt || !jwt.token) {
              console.error("JWT is undefined or has no token");
              return throwError(new Error("Invalid JWT token"));
            }
            return next.handle(this.addAuthenticationToken(req, jwt.token));
          })
        );
      } else {
        this.refreshTokenInProgress = true;
        this.refreshTokenSubject.next(null);
        return this.authService.refreshToken().pipe(
          switchMap((retrieveNewToken: any) => {
            // console.log("refresh token...", retrieveNewToken);
            this.refreshTokenInProgress = false;
            if (!retrieveNewToken || !retrieveNewToken.token) {
              // console.error("Retrieved token is undefined or has no token");
              return throwError(new Error("Invalid retrieved token"));
            }
            this.refreshTokenSubject.next(retrieveNewToken.token);
            return next.handle(this.addAuthenticationToken(req, retrieveNewToken.token));
          }),
          catchError((err) => {
            console.error("Error during token refresh", err);
            this.refreshTokenInProgress = false;
            this.authService.logout();
            let baseUrl = localStorage.getItem("baseUrl");
            // console.log("baseUrl...", baseUrl);
            if (baseUrl) {
              this.router.navigateByUrl(baseUrl);
            } else {
              this.router.navigateByUrl(ROUTE_PATHS.xe_login);
            }
            return of(err);
          })
        );
      }
    }
    
  addAuthenticationToken(
    request: HttpRequest<any>,
    token: any
  ): HttpRequest<any> {
    // const sessionToken: any = localStorage.getItem("x-access-token");
    const sessionToken: any = this.cookieService.get('x-access-token');
    console.log("sessionToken...",sessionToken)
    request.clone({
      headers: request.headers.set("Content-Type", "application/json"),
    });
    request.clone({
      headers: request.headers.set("Accept", "*/*"),
    });
    return request.clone({
      headers: request.headers.set("Authorization", `Bearer ${sessionToken}`),
    });
  }
}
