export const NUMBER = {
  zero: 0,
  one: 1,
  two: 2,
  three: 3,
  four: 4,
  five:5,
  seven: 7,
  eight: 8,
  nine: 9,
  ten: 10,
  eleven:11,
  twelve: 12,
  thirteen: 13,
  fourteen: 14,
  seventeen: 17,
  eighteen: 18,
  twenty: 20,
  thirty: 30,
  thirtyOne: 31,
  fourty: 40,
  fifty: 50,
  eighty: 80,
  hundred: 100,
  onehundredfifty: 150,
  twohundredfiftyfive: 255,
  ninehundrednintynine: 999,
  thousand: 1000,
  twothousand: 2000,

};
export const MINUS_NUMBER = {
  one: -1,
};
