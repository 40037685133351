import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from './api.service';
import { API_URLS, API_METHOD, ROUTE_PATHS } from '../constants';
import { IUserConfig } from '../models/user-config.model';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { HTTP_STATUS_CODE } from '../../core/constants/http-status.constant';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  public userConfig: IUserConfig;
  public isOnlyReviewer = false;
  constructor(
    private readonly apiService: ApiService,
    public http: HttpClient,
    private router: Router,
    private cookieService: CookieService
  ) { }

  getUserConfiguration() {
    // return localStorage.getItem('userConfigList') ? JSON.parse(localStorage.getItem('userConfigList')) : null;
    return this.cookieService.get('userConfigList') ? JSON.parse(this.cookieService.get('userConfigList')) : null;

    // let UserName = localStorage.getItem('UserName');
    // let UserEmail = localStorage.getItem('UserEmail');
    // let UserId = localStorage.getItem('UserId');
    // let token = localStorage.getItem('token');
    // let UserRoles = localStorage.getItem('UserRoles');
    // let x_access_token = localStorage.getItem('x-access-token');
    // console.log(UserName && UserEmail && UserId && token && UserRoles && x_access_token, "calling");
    // let userConfig: any = {
    //   "UserName": "",
    //   "UserEmail": "",
    //   "UserId": "",
    //   "token": "",
    //   "UserRoles": "",
    //   "x-access-token": ""
    // }
    // if (UserName && UserEmail && UserId && token && UserRoles && x_access_token) {
    //   userConfig = {
    //     "UserName": localStorage.getItem('UserName'),
    //     "UserEmail": localStorage.getItem('UserEmail'),
    //     "UserId": localStorage.getItem('UserId'),
    //     "token": localStorage.getItem('token'),
    //     "UserRoles": localStorage.getItem('UserRoles'),
    //     "x-access-token": localStorage.getItem('x-access-token')
    //   }
    //   return userConfig;
    // } else {
    //   return userConfig;
    //   // throw new Error({ status: 401 });
    // }
    // return this.apiService.invoke({
    //   path: API_URLS.userConfig,
    //   method: API_METHOD.post,
    // }, false, false, true);
  }

  setUserConfig(userConfig: any) {
    this.userConfig = JSON.parse(userConfig);
  }

  getUserInformation(apiUrl: any, apiData: any) {
    return this.http.post(`${environment.apiUrl}${apiUrl}`, apiData);
  }
  putUserInformation(apiUrl: any, apiData: any) {
    return this.http.put(`${environment.apiUrl}${apiUrl}`, apiData);
  }

  currentUserConfig(): IUserConfig {
    // let userConfigList = localStorage.getItem('userConfigList');
    let userConfigList = this.cookieService.get('userConfigList');
    if (userConfigList) {
      this.userConfig = JSON.parse(userConfigList);
      return this.userConfig;
    } else {
      return null;
    }
  }

  setIsOnlyReviewer(value){
    this.isOnlyReviewer = value;
  }

  redirectToHome(userConfig: any): void {
    let configList = userConfig;
    let userId: any = localStorage.getItem('userDetailsData') || localStorage.getItem("userId") || localStorage.getItem("UserId");
    let roles: string[] = configList.UserRoles.map((list: any) => list.RoleName);
    let isReviewer:Boolean = roles.includes("Reviewer") && roles.length == 1 ? true :false;
    // let isEaforms: Boolean = roles.includes("EA Forms CSR") && roles.length == 1 ? true : false;
    // let isEaCsr: Boolean = roles.includes("EA-CSR") && roles.length == 1 ? true : false;
    // let isEasyView: Boolean = roles.includes("EAsyView CSR") && roles.length == 1 ? true : false;
    let url: string = `/${ROUTE_PATHS.DASHBOARD}`;
    if(configList.MODULE_ACCESS.length == 0 && userId != 1)
      url = `/not-found`;
    else if(configList.singleCompany && "CompanyID" in configList.singleCompany) {
      url = configList.MODULE_ACCESS.length > 0  ? `/${ROUTE_PATHS.DASHBOARD}` : `/not-found`;
      if(isReviewer) {
        this.isOnlyReviewer = true;
        url = configList.MODULE_ACCESS.length > 0  ? `/${ROUTE_PATHS.DASHBOARD}` : `/not-found`;
      }
      // else if(isEaforms) {
      //   if(configList.MODULE_ACCESS.indexOf(11) > -1)
      //     url = configList.MODULE_ACCESS.indexOf(11) > -1 ? `/${ROUTE_PATHS.DASHBOARD}` : `/not-found` ;
      // }
      // else if(isEasyView) {
      //   if(configList.MODULE_ACCESS.indexOf(1) > -1)
      //     url =configList.MODULE_ACCESS.indexOf(1) > -1 ? `/${ROUTE_PATHS.DASHBOARD}` : `/not-found` ;
      // }
      // else if(isEaCsr) {
      //   if(configList.MODULE_ACCESS.indexOf(8) > -1)
      //     url =configList.MODULE_ACCESS.indexOf(8) > -1 ? `/${ROUTE_PATHS.DASHBOARD}` : `/not-found` ;
      //   else
      //     url = `/not-found`;
      // }
    }
    else {
      if(isReviewer) {
        this.isOnlyReviewer = false;
        url =configList.MODULE_ACCESS.length > 0  ? `/${ROUTE_PATHS.DASHBOARD}` : `/not-found`;
      }
      // else if(isEaCsr) {
      //   url = configList.MODULE_ACCESS.indexOf(8) > -1 ? `/${ROUTE_PATHS.DASHBOARD}` : `/not-found`;
      // }
     }
    console.log(url, "url")
    this.router.navigateByUrl(url);
  }
}
