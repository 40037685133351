export const SOCKET_EVENT = {
  xppPdfReady: 'xppdfready',
  xppXmlReady: 'xppxmlready',
  xppError: 'xpperror',
  ditaOtError: 'ditaoterror',
  iXBRLGenComplete: 'iXBRLGenComplete',
  iXBRLGenError: 'iXBRLGenError',
  edgarHTMLGenComplete: 'edgarHTMLGenComplete',
  edgarHTMLGenError: 'edgarHTMLGenError',
  generateFileError: 'genfiles',
  wordGenComplete: 'wordGenComplete',
  wordGenError: 'wordGenError',
  wordmlRenderError: 'uploaddocument_error',
  wordmlRenderNotifications: 'wordml_notifications'
};
export const EDITOR_SOCKET_EVENT = {
  lockTopic: 'locktopic',
  unlockTopic: 'unlocktopic',
  getLockedTopics: 'getlockedtopics',
}
export const SOCKET_STATE = {
  completed: 3,
  error: 2,
};
